// src/contexts/RegionContext.tsx
import { Router } from "mdi-material-ui";
import { useRouter } from "next/router";
import React, { createContext, useState, ReactNode, useEffect } from "react";
import apiConfig from "src/configs/api";
import { defaultRegion } from "src/configs/constants";
import useQueryParams from "src/hooks/useQueryParams";
import { Region } from "src/types/regions";
import { useAuth } from "src/hooks/useAuth";
import { regions as availableRegions } from "src/utils/regions";

interface RegionContextType {
  selectedRegion: Region | null;
  setSelectedRegion: (region: Region) => void;
  currentRegion: string;
}

interface RegionProviderProps {
  children: ReactNode;
}

export const RegionContext = createContext<RegionContextType | undefined>(
  undefined,
);

export const RegionProvider: React.FC<RegionProviderProps> = ({ children }) => {
  const { user } = useAuth();
  const queryParams = useQueryParams();
  const router = useRouter();
  const regionValue = queryParams[apiConfig.currentRegion] || defaultRegion;
  const projectID = queryParams[apiConfig.projectId] as string;
  const selected =
    user &&
    user.projects &&
    user.projects[projectID] &&
    Object.values(user.projects[projectID]).length
      ? Object.values(user.projects[projectID]).filter(
          (region: Region) => region.identifier === regionValue,
        )
      : availableRegions.filter(
          (availableRegion) => availableRegion.identifier === regionValue,
        );
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(
    selected[0] || null,
  );
  const currentRegion =
    selectedRegion && selectedRegion.displayName
      ? selectedRegion.displayName
      : "";

  useEffect(() => {
    if (
      !queryParams[apiConfig.currentRegion] &&
      selected[0] &&
      selected[0].identifier
    ) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            [apiConfig.currentRegion]: selected[0].identifier,
          },
        },
        undefined,
        { shallow: true },
      );
    }
  }, []);
  useEffect(() => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!queryParams[apiConfig.timezone]) {
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            [apiConfig.timezone]: timezone,
          },
        },
        undefined,
        { shallow: true },
      );
    }
  }, []);

  return (
    <RegionContext.Provider
      value={{ selectedRegion, setSelectedRegion, currentRegion }}
    >
      {children}
    </RegionContext.Provider>
  );
};
