// ** React Imports
import React, { createContext, useEffect, useState, ReactNode } from "react";

// ** Sentry Imports
import * as Sentry from "@sentry/nextjs";

// ** Next Import
import { useRouter } from "next/router";

// ** axios
import axiosService from "src/services/axios";
import axios from "axios";

// ** Config
import apiConfig from "src/configs/api";

// ** Types
import {
  AuthValuesType,
  LoginParams,
  ErrCallbackType,
  UserDataType,
  Projects,
} from "src/context/types";
import { toast } from "react-hot-toast";

import { loginResponseSchema } from "src/schema/auth/loginResponse";
import Cookie from "js-cookie";
import { defaultRegion } from "src/configs/constants";
import { deleteCookie, pushToLogin } from "src/configs/helper";
import { publicCloudLogoutUrl } from "src/utils/publicCloud";
import { errorLogInSentry } from "src/store/sentry";
import { defaultService, serviceMapping } from "src/utils/regions";

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  saveUserData: () => {},
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const updateRegionService = (userProjects: Projects): void => {
  if (!userProjects) {
    return;
  }
  Object.values(userProjects).forEach((project) => {
    Object.values(project).forEach((region) => {
      if (!region.service) {
        region.service = defaultService;
      } else {
        region.service = serviceMapping[region.service];
      }
    });
  });

  return;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState<boolean>(
    defaultProvider.isInitialized,
  );

  // ** Hooks
  const router = useRouter();

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      setIsInitialized(true);
      setLoading(true);
      const storedToken = localStorage.getItem(apiConfig.storageTokenKeyName);
      const userRemember = Cookie.get(apiConfig.userRemember);
      if (!storedToken || user != null) {
        if (userRemember && !storedToken) {
          await axiosService
            .post(apiConfig.loginEndpoint)
            .then(async (response) => {
              if (!response || !response.data || !response.data.data) {
                throw new Error("Authentication Failed");
              }
              router.replace(await saveUserData(response.data));
            })
            .catch(() => {
              setUser(null);
              deleteCookie(apiConfig.whmcsCookie);
              pushToLogin();
            });
          setLoading(false);
        } else {
          setLoading(false);
          return;
        }
        setLoading(false);
        return;
      }

      await axiosService
        .get(apiConfig.meEndpoint)
        .then((response) => {
          if (!response || !response.data || !response.data.data) {
            throw new Error("Authentication Failed");
          }
          Sentry.setUser({ email: response.data.data.email ?? "" });
          if (response.data.data.projects) {
            updateRegionService(response.data.data.projects);
          }
          setUser(response.data.data);
        })
        .catch(async (error) => {
          errorLogInSentry(
            error,
            {},
            "Authentication Failed",
            "initAuth",
            "src/context/AuthContext.tsx",
          );
          if (
            error.message &&
            error.message.toLowerCase() === "unauthorized" &&
            Cookie.get(apiConfig.userRemember)
          ) {
            router.push("/");
            await axios
              .post(apiConfig.proxyLoginEndpoint)
              .then(async (response) => {
                if (!response || !response.data || !response.data.data) {
                  throw new Error("Authentication Failed");
                }
                router.replace(await saveUserData(response.data));
              })
              .catch(() => {
                setUser(null);

                deleteCookie(apiConfig.whmcsCookie);
                pushToLogin();
              })
              .finally(() => {
                setLoading(false);
                return;
              });
          }
          pushToLogin();
        });
      setLoading(false);
    };
    initAuth().catch(() =>
      toast.error(
        "Unable to process the request, please try again after sometime",
      ),
    );
  }, [user]);

  const saveUserData = async (response: {
    data: UserDataType;
  }): Promise<string> => {
    const returnUrl = '/dashboard';
    if (response.data.whmcsCookie) {
      response.data.whmcsCookie.forEach((element) => {
        const whmcsCookie = element.split("=");
        Cookie.set(whmcsCookie[0], whmcsCookie[1].split(";")[0]);
      });
    }
    localStorage.setItem(
      apiConfig.storageTokenKeyName,
      response.data[apiConfig.storageTokenKeyName as "accessToken"],
    );
    Sentry.setUser({ email: response.data.email ?? "" });
    if (response.data.projects) {
      updateRegionService(response.data.projects);
    }
    setUser(response.data);
    let redirectURL: string | string[] = router.asPath;
    redirectURL =
      returnUrl && !["/", "/logout",].includes(String(returnUrl).toLowerCase())
        ? returnUrl
        : router.asPath;

    const projectId = Object.keys(response.data?.projects)?.length
      ? Object.keys(response.data.projects)[0]
      : "";
    const region =
      projectId &&
      response.data.projects[projectId] &&
      Array.isArray(Object.keys(response.data.projects[projectId])) &&
      Object.keys(response.data.projects[projectId]).length
        ? Object.keys(response.data.projects[projectId])[0]
        : defaultRegion;

    // Add project_id to redirectURL
    if (redirectURL.includes("?")) {
      redirectURL += `&region=${region}`;
    } else {
      redirectURL += `?region=${region}`;
    }
    if (projectId) {
      redirectURL += `&project_id=${projectId}`;
    }
    return redirectURL as string;
  };

  const handleLogin = (params: LoginParams, errorCallback: ErrCallbackType) => {
    axios
      .post(apiConfig.proxyLoginEndpoint, params)
      .then(async (response) => {
        if ((response.data && response.data.error) || !response.data.data) {
          errorCallback(response.data.error);

          return;
        }
        loginResponseSchema
          .validate(response.data.data)
          .then(async () => {
            await router.replace(await saveUserData(response.data));
          })
          .catch((error) => {
            errorLogInSentry(
              error,
              {},
              "POST",
              "handleLoginValidate",
              "src/context/AuthContext.tsx",
            );
            errorCallback(error);
          });
      })
      .catch((error) => {
        errorLogInSentry(
          error,
          {},
          "POST",
          "handleLogin",
          "src/context/AuthContext.tsx",
        );
        errorCallback(error);
      });
  };

  const handleLogout = async (errorCallback: ErrCallbackType = () => {}) => {
    if (!localStorage.getItem(apiConfig.storageTokenKeyName)) {
      setUser(null);

      deleteCookie(apiConfig.whmcsCookie);
      deleteCookie(apiConfig.userRemember);
      setIsInitialized(false);
      window.location.href = publicCloudLogoutUrl;
      return false;
    }
    axiosService
      .get(apiConfig.logoutEndpoint)
      .then(async (response) => {
        if (
          response.data &&
          response.data.message &&
          response.data.message.toLowerCase() === "success"
        ) {
          localStorage.removeItem(apiConfig.storageTokenKeyName);

          deleteCookie(apiConfig.whmcsCookie);
          deleteCookie(apiConfig.userRemember);

          setUser(null);
          setIsInitialized(false);
          window.location.href = publicCloudLogoutUrl;
        } else {
          await router.push("/");
        }
      })
      .catch((error) => {
        errorLogInSentry(
          error,
          {},
          "GET",
          "handleLogout",
          "src/context/AuthContext.tsx",
        );
        errorCallback(error);
      });
  };

  const values: AuthValuesType = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    saveUserData,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
export { AuthContext, AuthProvider };
