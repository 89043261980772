import { AxiosError } from "axios";
import axios from "src/services/axios";
import apiConfig from "src/configs/api";
import { errorLogInSentry } from "src/store/sentry";
import showToast from "src/utils/notifications";
import { toastMessage } from "src/language/en/message";
import { ProjectType } from "src/context/types";

interface OrderProjectData {
  projectName: string;
}

interface OrderProjectResponse {
  data: {
    id: string;
    name: string;
    // Add other response fields as needed
  };
}

export const fetchProjects = async (): Promise<ProjectType> => {
  try {
    const response = await axios.get(apiConfig.userProjects);

    return response?.data?.data ?? [];
  } catch (error) {
    errorLogInSentry(
      error,
      {},
      "GET",
      "fetchProjects",
      "src/store/projects/index.ts",
    );
    throw error;
  }
};

export const orderProject = async (
  data: OrderProjectData,
): Promise<OrderProjectResponse> => {
  try {
    const response = await axios.post(apiConfig.addProject, data);

    if (response?.data) {
      showToast(
        "success",
        toastMessage.create?.success || "Project created successfully",
      );
      return response.data;
    }

    throw new Error("Invalid response format");
  } catch (error: unknown) {
    errorLogInSentry(
      error,
      {},
      "POST",
      "orderProject",
      "src/store/projects/order.ts",
    );

    showToast(
      "error",
      `${toastMessage.create?.error || "Failed to create"} project. ${
        error instanceof AxiosError ? error.message : "Please try again."
      }`,
    );

    throw error;
  }
};
