export const moduleNames: { [key: string]: { [key: string]: string } } = {
  router: {
    multipleCap: "Routers",
    multipleSmall: "routers",
    singleCap: "Router",
    singleSmall: "router",
  },
  routers: {
    version1: "Routers",
    version2: "routers",
    version3: "Router",
    version4: "router",
  },
  vpcConstant: {
    version1: "VPC",
    version2: "VPCs",
  },
  vpc: {
    version1: "VPC",
    version2: "VPCs",
    version3: "vpc",
    version4: "vpcs",
    version5: "Virtual Private Cloud",
  },
  subnet: {
    version1: "Subnets",
    version2: "Subnet",
    version3: "subnet",
    version4: "subnets",
  },
  subnetConstant: {
    version1: "Subnets",
    version2: "Subnet",
    version3: "subnet",
    version4: "subnets",
  },
  port: {
    version1: "Ports",
    version2: "Port",
    version3: "port",
    version4: "ports",
  },
  vpcPortConstant: {
    version1: "Ports",
    version2: "Port",
  },
  gateway: {
    version1: "Gateways",
    version2: "Gateway",
    version3: "gateway",
  },
  keyPair: {
    version1: "Key Pair",
    version2: "Key pair",
    version3: "key pair",
    version4: "key",
    version5: "Key",
    version6: "key pairs",
  },
  users: {
    version1: "Users",
    version2: "User",
    version3: "users",
    version4: "user",
  },
  snapshot: {
    version1: "Snapshot",
    version2: "Snapshots",
    version3: "snapshot",
    version4: "snapshots",
  },
  volume: {
    version1: "Volume",
    version2: "Volumes",
    version3: "volume",
    version4: "volumes",
  },
  backup: {
    version1: "Backup",
    version2: "Backups",
    version3: "backup",
    version4: "backups",
  },
  accesskey: {
    version1: "Access Key",
    version2: "Access-Key",
    version3: "Access Key List",
    version4: "access key",
    version5: "Access key",
    version6: "Access keys",
  },
  instance: {
    version1: "Instance",
    version2: "Instances",
    version3: "instance",
    version4: "instances",
  },
  securityGroup: {
    version1: "Security Group",
    version2: "Security group",
    version3: "security group",
    version4: "Security groups",
    version5: "Security Groups",
    version6: "security groups",
  },
  securityGroupRule: {
    version1: "Security group rule",
    version2: "Security group rules",
    version3: "security group rules",
    version4: "security group rule",
  },
  inboundRules: {
    version1: "Inbound Rules",
  },
  outboundRules: {
    version1: "Outbound Rules",
  },
  vCPUs: {
    version1: "vCPUs",
  },
  RAM: {
    version1: "RAM",
  },
  storage: {
    versio1: "Storage",
  },
  floating_ip: {
    version1: "Floating IPs",
  },
  image: {
    version1: "Image",
    version2: "Images",
    version3: "images",
    version4: "image",
  },
  floatingIp: {
    version1: "Floating IP",
    version2: "Floating IPs",
    version3: "floating IP",
    version4: "floating IPs",
  },
  interfaces: {
    version1: "Interface",
    version2: "Interfaces",
    version3: "interface",
    version4: "interfaces",
  },
  staticRoutes: {
    version1: "Static route",
    version2: "Static routes",
    version3: "static route",
    version4: "static routes",
  },
  actionLogs: {
    version1: "Action Log",
    version2: "Action Logs",
    version3: "action log",
    version4: "action logs",
  },
  instanceInterfaces: {
    version1: "Interface",
    version2: "Interfaces",
    version3: "interface",
    version4: "interfaces",
  },
  loadBalancer: {
    version1: "Load Balancer",
    version2: "Load Balancers",
    version3: "load balancer",
    version4: "load balancers",
  },
  vpnIpsec: {
    version1: "IPSec Policies",
    version2: "IPSec Policy",
    version3: "IPSec policies",
    version4: "IPSec policy",
    version5: "IPSec",
  },
  rule: {
    version1: "Rule",
    version2: "Rules",
    version3: "rule",
    version4: "rules",
  },
  vpn: {
    version1: "VPN",
    version2: "vpn",
  },
  vpnIke: {
    version1: "IKE Policies",
    version2: "IKE Policy",
    version3: "IKE policies",
    version4: "IKE policy",
    version5: "IKE",
  },
  vpnServices: {
    version1: "VPN Service",
    version2: "VPN Services",
    version3: "VPN service",
    version4: "VPN services",
  },
  endpointGroups: {
    version1: "Endpoint Group",
    version2: "Endpoint Groups",
    version3: "endpoint group",
    version4: "endpoint groups",
    version5: "Endpoint group",
  },
  ipSecSite: {
    version1: "IPSec Site Connection",
    version2: "IPSec Site Connections",
    version3: "IPSec site connection",
    version4: "IPSec site connections",
  },
  activityLogs: {
    version1: "Activity Log",
    version2: "Activity Logs",
    version3: "activity log",
    version4: "activity logs",
  },
  billingReport: {
    version1: "Report",
    version2: "Reports",
    version3: "report",
    version4: "reports",
  },
  userProjects: {
    version1: "Project",
    version2: "Projects",
    version3: "project",
    version4: "projects",
  },
  backupCenter: {
    version1: "Backup Center",
    version2: "Backup Centers",
    version3: "backup center",
    version4: "backup centers",
  },
  backupCenterTask: {
    version1: "Job",
    version2: "Jobs",
    version3: "job",
    version4: "jobs",
  },
  backupCenterNotification: {
    version1: "Notification",
    version2: "Notifications",
    version3: "notification",
    version4: "notifications",
  },
  backupCenterPolicy: {
    version1: "Policy",
    version2: "Policies",
    version3: "policy",
    version4: "policies",
  },
  backupCenterConfiguredBackup: {
    version1: "Configured Backup",
    version2: "Configured Backups",
    version3: "configured backup",
    version4: "configured backups",
    version5: "Configure Backup",
    version6: "configuring backup",
    version7: "configure backup",
  },
};
