// ** React Imports
import React, { ReactNode } from "react";

// ** Next Imports
import Head from "next/head";
import { Router, useRouter } from "next/router";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import appConfig from "src/configs/app";

// ** Import for the React notifications
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ** Loader Import
import NProgress from "nprogress";

// ** Emotion Imports
import { CacheProvider } from "@emotion/react";
import type { EmotionCache } from "@emotion/cache";

import themeConfig from "src/configs/themeConfig";

// ** Third Party Import
import { Toaster } from "react-hot-toast";

// ** Component Imports

import ThemeComponent from "src/@core/theme/ThemeComponent";

import WindowWrapper from "src/layouts/components/window-wrapper";

import {
  SettingsConsumer,
  SettingsProvider,
} from "src/@core/context/settingsContext";

// ** Styled Components
import ReactHotToast from "src/@core/styles/libs/react-hot-toast";

// ** Utils Imports
import { createEmotionCache } from "src/@core/utils/create-emotion-cache";

// ** Prismjs Styles
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx";
import "prismjs/components/prism-tsx";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Global css styles
import "styles/globals.css";
import "styles/tailwind.css";
import "styles/css/style.css";

import Layout from "src/layouts/Layout";
import AclGuard from "src/components/auth/aclGuard";
import { defaultACLObj } from "src/configs/acl";
import Spinner from "src/layouts/components/spinner";
import GuestGuard from "src/components/auth/GuestGuard";
import AuthGuard from "src/components/auth/AuthGuard";
import { AuthProvider } from "src/context/AuthContext";
import { RegionProvider } from "src/context/RegionContext";
import { S3Provider } from "src/context/S3Context";
import FigmaLayout from "src/figmaLayout/Layout";
import S3FileUploadProvider from "src/context/S3FileUploadContext";
import { TimezoneProvider } from "src/context/TimeZoneContext";
import ProjectGuard from "src/components/auth/ProjectGuard";
import ProjectProvider from "src/context/ProjectContext";
import KYCProvider from "src/context/KYCContext";
import { pagesWithoutContext } from "src/configs/constants";

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};
type GuardProps = {
  authGuard: boolean;
  guestGuard: boolean;
  children: ReactNode;
};

const clientSideEmotionCache = createEmotionCache();

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on("routeChangeStart", () => {
    NProgress.start();
  });
  Router.events.on("routeChangeError", () => {
    NProgress.done();
  });
  Router.events.on("routeChangeComplete", () => {
    NProgress.done();
  });
}

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const getLayout = () => {
    if (Component.getLayout) {
      return Component.getLayout(<Component {...pageProps} />);
    }
    if (appConfig.figmaLayoutSwitcher.toLowerCase() === "on") {
      return (
        <FigmaLayout>
          <Component {...pageProps} />
        </FigmaLayout>
      );
    }

    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  };

  const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
    if (guestGuard) {
      return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;
    } else if (!guestGuard && !authGuard) {
      return <>{children}</>;
    } else {
      if (!projectGuard) {
        return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
      } else {
        return (
          <AuthGuard fallback={<Spinner />}>
            <ProjectGuard fallback={<Spinner />}>{children}</ProjectGuard>
          </AuthGuard>
        );
      }
    }
  };
  // Variables
  const setConfig = Component.setConfig ?? undefined;
  const aclAbilities = Component.acl ?? defaultACLObj;
  const guestGuard = Component.guestGuard ?? false;
  const authGuard = Component.authGuard ?? true;
  const projectGuard = Component.projectGuard ?? true;

  const queryClient = new QueryClient();
  const router = useRouter();
  const pathname = router.pathname;
  const isExcludedRoute = pagesWithoutContext.includes(pathname);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{appConfig.metaTitle}</title>
        <meta name="description" content={appConfig.metaTitle} />
        <meta name="keywords" content="" />
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <ProjectProvider>
          <KYCProvider>
            <S3Provider>
              <S3FileUploadProvider>
                <SettingsProvider
                  {...(setConfig ? { pageSettings: setConfig() } : {})}
                >
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          <WindowWrapper>
                            <Guard
                              authGuard={authGuard}
                              guestGuard={guestGuard}
                            >
                              {isExcludedRoute ? (
                                getLayout()
                              ) : (
                                <RegionProvider>
                                  <TimezoneProvider>
                                    <AclGuard
                                      aclAbilities={aclAbilities}
                                      guestGuard={guestGuard}
                                    >
                                      <QueryClientProvider client={queryClient}>
                                        {getLayout()}
                                      </QueryClientProvider>
                                    </AclGuard>
                                  </TimezoneProvider>
                                </RegionProvider>
                              )}
                            </Guard>
                          </WindowWrapper>
                          <ToastContainer />
                          <ReactHotToast>
                            <Toaster
                              position={settings.toastPosition}
                              toastOptions={{ className: "react-hot-toast" }}
                            />
                          </ReactHotToast>
                        </ThemeComponent>
                      );
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </S3FileUploadProvider>
            </S3Provider>
          </KYCProvider>
        </ProjectProvider>
      </AuthProvider>
    </CacheProvider>
  );
};

export default App;
