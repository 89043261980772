import appConfig from "src/configs/app";
import { Region } from "src/types/regions";

const regions: Region[] = [
  {
    name: "US East (Atlanta)",
    identifier: "us-east-at-1",
    displayName: "atlanta",
    service: "openstack",
  },
  {
    name: "Asia Pacific (Mumbai)",
    identifier: "ap-south-mum-1",
    displayName: "mumbai",
    service: "openstack",
  },
  // {
  //   name: "Asia Pacific (Noida)",
  //   identifier: "ap-south-noi-1",
  //   displayName: "noida",
  //   service: "openstack",
  // },
];

if (appConfig.showDelhiRegion) {
  regions.push({
    name: "Asia Pacific (Delhi)",
    identifier: "ap-south-del-1",
    displayName: "delhi",
    service: "e2e",
  });
}

const serviceMapping: { [key: string]: string } = {
  type1: "openstack",
  type2: "e2e",
};

const defaultService = "openstack";

export { regions, serviceMapping, defaultService };
