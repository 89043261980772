import { AbilityBuilder, Ability } from "@casl/ability";
import { useAuth } from "src/hooks/useAuth";
import { Region } from "src/types/regions";
import getACLSubjects from "src/utils/regionAccessList";

export type Subjects = string;
export type Actions =
  | "manage"
  | "create"
  | "show"
  | "update"
  | "delete"
  | "register";

export type AppAbility = Ability<[Actions, Subjects]> | undefined;

// eslint-disable-next-line no-redeclare
export const AppAbility = Ability as any;
export type ACLObj = {
  action: Actions;
  subject: string;
};

const DefineRulesFor = (user: object, selectedRegion: Region | null) => {
  const auth = useAuth();
  const region =
    selectedRegion && selectedRegion.identifier
      ? selectedRegion.identifier
      : "";
  const service =
    selectedRegion && selectedRegion.service
      ? selectedRegion.service.toLowerCase()
      : "";
  if (!auth.user) {
    return;
  }
  const { can, cannot, rules } = new AbilityBuilder(AppAbility);
  cannot("manage", "all");
  can(["view"], "acl");
  can(["view"], "logout");
  const aclSubjects = getACLSubjects(service, region);
  can(["view"], aclSubjects);
  return rules;
};

export const buildAbilityFor = (
  user: object,
  region: Region | null,
): AppAbility => {
  return new AppAbility(DefineRulesFor(user, region), {
    // https://casl.js.org/v5/en/guide/subject-type-detection
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
  });
};

export const defaultACLObj: ACLObj = {
  action: "show",
  subject: "client-home",
};

export default DefineRulesFor;
