import { useRouter } from "next/router";
import React, {
  useState,
  createContext,
  ReactNode,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import { useAuth } from "src/hooks/useAuth";
import { ProjectType } from "src/context/types";
import showToast from "src/utils/notifications";
import { fetchProjects } from "src/store/projects";
import apiConfig from "src/configs/api";
import { getRoute } from "src/routes";
import { isEmptyObject } from "src/configs/helper";
import { toastMessage } from "src/language/en/message";

interface ProjectContextType {
  projects: ProjectType | null;
  selectedProjectID: string;
  isProjectsFetching: boolean;
  setSelectedProjectID: React.Dispatch<React.SetStateAction<string>>;
}

const defaultValues = {
  projects: null,
  isProjectsFetching: false,
  selectedProjectID: "",
  setSelectedProjectID: () => {},
};

export const ProjectContext = createContext<ProjectContextType>(defaultValues);

const ProjectProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth();
  const router = useRouter();
  const [projects, setProjects] = useState<ProjectType | null>(null);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const [selectedProjectID, setSelectedProjectID] = useState<string>("");

  const queryParams = router.query;
  const projectID = queryParams[apiConfig.projectId] as string;

  const getProjects = useCallback(async () => {
    if (!router.isReady || !user) {
      return;
    }
    setIsProjectsLoading(true);
    try {
      const projectsResponse: ProjectType = await fetchProjects();
      if (
        !projectsResponse ||
        isEmptyObject(projectsResponse) ||
        !Object.keys(projectsResponse).length
      ) {
        setProjects(null);
        setIsProjectsLoading(false);
        return;
      }
      setProjects(projectsResponse);
      if (projectID && projectsResponse[projectID]) {
        setSelectedProjectID(projectID);
      } else {
        if (
          projectID &&
          !projectsResponse[projectID] &&
          router.pathname === "/dashboard"
        ) {
          showToast(
            "error",
            toastMessage.frontendError.defaultProjectIdErrorMessage,
          );
        }
        const defaultProjectId = Object.keys(projectsResponse)[0];
        const hostingId = projectsResponse[defaultProjectId].hostingId;
        setSelectedProjectID(defaultProjectId);
        router.push({
          pathname: getRoute("dashboard"),
          query: {
            ...router.query,
            [apiConfig.projectId]: defaultProjectId,
            [apiConfig.hostingId]: hostingId,
          },
        });
      }
    } catch (error) {
      showToast(
        "error",
        toastMessage.frontendError.projectFetchingErrorMessage,
      );
    } finally {
      setIsProjectsLoading(false);
    }
  }, [user, projectID, router]);

  useEffect(() => {
    if (user) {
      getProjects();
    } else {
      setProjects(null);
      setSelectedProjectID("");
    }
  }, [user, getProjects]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (url === "/dashboard" && user) {
        getProjects();
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    if (router.pathname === "/dashboard" && user) {
      getProjects();
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, router.pathname, user, getProjects]);

  const contextValue = useMemo(
    () => ({
      projects,
      isProjectsFetching: isProjectsLoading,
      selectedProjectID,
      setSelectedProjectID,
    }),
    [projects, isProjectsLoading, selectedProjectID, setSelectedProjectID],
  );

  return (
    <ProjectContext.Provider value={contextValue}>
      {children}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
