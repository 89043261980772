import React, { useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  ButtonBase,
  ListItemText,
} from "@mui/material";
import ArrowDown from "src/assets/icons/arrowDown";
import { useTheme } from "@mui/material/styles";

import apiConfig from "src/configs/api";
import { ucwords } from "src/configs/helper";
import { useRegion } from "src/hooks/useRegion";
import { useRouter } from "next/router";
import { useS3Account } from "src/hooks/useS3Account";
import { getRoute } from "src/routes";
import { Region } from "src/types/regions";
import { useAuth } from "src/hooks/useAuth";
import { regions as availableRegions } from "src/utils/regions";
import { noidaServiceRegion } from "src/configs/constants";

const RegionSelector: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { selectedRegion, setSelectedRegion } = useRegion();
  const { resetS3AccountKeys } = useS3Account();

  const { user, setUser } = useAuth();
  const [regions, setRegions] = useState<Region[]>([]);
  const router = useRouter();
  const theme = useTheme();

  useEffect(() => {
    const queryParams = router.query;
    const projectID = queryParams[apiConfig.projectId] as string;
    if (!projectID || !user?.projects?.[projectID]) {
      setRegions(availableRegions);
    } else {
      const regionsArray = Object.values(user.projects[projectID]);
      setRegions(regionsArray);
    }
  }, [router.query, user]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isRegionSelected = (region: Region): boolean => {
    const queryParams = router.query;
    const currentRegion = queryParams[apiConfig.currentRegion] as string;
    if (region.identifier === currentRegion) {
      return true;
    }

    return false;
  };

  const handleRegionSelection = (region: Region): void => {
    if (isRegionSelected(region)) {
      handleClose();
      return;
    }
    setSelectedRegion(region);
    resetS3AccountKeys();
    setUser(null);
    let pathname = getRoute("dashboard");
    if (region.identifier === noidaServiceRegion && !region.is_accessible) {
      pathname = getRoute("getStarted");
    }
    router.push(
      {
        pathname: pathname,
        query: {
          ...router.query,
          [apiConfig.currentRegion]: region.identifier,
        },
      },
      undefined,
      { shallow: true },
    );
    handleClose();
  };

  return (
    <React.Fragment>
      <ButtonBase
        onClick={(event) => handleClick(event)}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <Typography
          variant="body1"
          marginRight="10px"
          fontWeight={600}
          fontSize={"14px !important"}
          fontFamily={"Poppins, sans-serif"}
          letterSpacing={"0 !important"}
          color={theme.palette.grey[600]}
        >
          {selectedRegion?.displayName
            ? ucwords(selectedRegion.displayName)
            : "No region available"}
        </Typography>
        <ArrowDown />
      </ButtonBase>
      <Menu
        id="region-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ style: { padding: 0 } }}
      >
        {regions?.length
          ? regions.map((region, index) => (
              <MenuItem
                key={region.identifier}
                onClick={() => handleRegionSelection(region)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color:
                    selectedRegion &&
                    selectedRegion.identifier === region.identifier
                      ? "primary.main"
                      : "inherit",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }} gap={3}>
                  <ListItemText
                    primary={region.name}
                    primaryTypographyProps={{
                      style: {
                        fontWeight: 500,
                        color:
                          selectedRegion &&
                          selectedRegion.identifier === region.identifier
                            ? theme.palette.primary.main
                            : "inherit",
                      },
                    }}
                  />
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    marginLeft: "8px",
                    color:
                      selectedRegion &&
                      selectedRegion.identifier === region.identifier
                        ? theme.palette.primary.main
                        : "inherit",
                  }}
                >
                  {region.identifier ? region.identifier : ""}
                </Typography>
              </MenuItem>
            ))
          : null}
      </Menu>
    </React.Fragment>
  );
};

export default RegionSelector;
