import { noidaServiceRegion } from "src/configs/constants";
import { regionProviderMapping } from "src/utils/S3/S3Constants";

const COMMON_ACL_SUBJECTS = [
  "home",
  "kyc",
  "zoho-dashboard",
  "zoho-tickets",
  "select-region",
  "order-project",
];

const getACLSubjects = (service: string, region: string): string[] => {
  switch (service) {
    case "openstack":
      {
        let aclSubjects = [
          ...COMMON_ACL_SUBJECTS,
          "dashboard",
          "instances",
          "images",
          "volumes",
          "snapshots",
          "routers",
          "floating-ips",
          "security-groups",
          "key-pairs",
          "resources",
          "vpc",
          "subnets",
          "ports",
          "k8s-overview",
          "k8sSecurityGroups",
          "k8sLoadBalancer",
          "k8sVolumes",
          "k8sSnapshots",
          "load-balancers",
          "users",
          "backup",
          "horizon-portal",
          "logs",
          "activity-logs",
          "backup-centers",
          "backup-centers-dashboard",
          "backup-centers-policies",
          "backup-centers-notifications",
          "backup-centers-configure",
          "backup-centers-tasks",
        ];

        if (region !== noidaServiceRegion) {
          aclSubjects = [
            ...aclSubjects,
            "templates",
            "deployments",
            "vpns",
            "billing-overview",
            "billing-summary",
          ];
        }

        if (Object.keys(regionProviderMapping).includes(region)) {
          aclSubjects = [
            ...aclSubjects,
            "s3-buckets",
            "s3-users",
            "s3-access-keys",
          ];
        }

        return aclSubjects;
      }
      break;
    case "e2e":
      {
        return [
          ...COMMON_ACL_SUBJECTS,
          "dashboard",
          "instances",
          "key-pairs",
          "volumes",
          "floating-ips",
          "security-groups",
          "vpc",
        ];
      }
      break;
    default: {
      let aclSubjects = [...COMMON_ACL_SUBJECTS];
      if (region === noidaServiceRegion) {
        aclSubjects = [...aclSubjects, "get-started"];
      }
      return aclSubjects;
    }
  }
};

export default getACLSubjects;
