import { toast } from "react-toastify";

import { useRouter } from "next/router";
import React, {
  ReactNode,
  ReactElement,
  useEffect,
  useState,
  useCallback,
} from "react";

import { useAuth } from "src/hooks/useAuth";
import apiConfig from "src/configs/api";
import useQueryParams from "src/hooks/useQueryParams";
import { getRoute, getRouteWithParams } from "src/routes";
import { toastMessage } from "src/language/en/message";
import { Region } from "src/types/regions";
import useProjects from "src/hooks/useProjects";
import useModal from "src/hooks/useModal";
import ProjectDialog from "src/components/dialogs/ProjectDialog";

interface ProjectGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

const ProjectGuard = (props: ProjectGuardProps) => {
  const { children, fallback } = props;

  const router = useRouter();
  const queryParams = useQueryParams();
  const projectValue = (queryParams[apiConfig.projectId] as string) || null;
  const currentRegion =
    (queryParams[apiConfig.currentRegion] as string) || null;
  const { loading: authLoading, user } = useAuth();
  const { projects } = useProjects();
  const [showFallback, setShowFallback] = useState<boolean>(true);
  const { handleOpenModal, handleCloseModal, modalActive } = useModal();

  const isProjectAccessible = (
    projectRegions: {
      [key: string]: Region;
    },
    currentRegion: string,
  ): string => {
    if (
      !projectRegions ||
      !currentRegion ||
      !projectRegions[currentRegion] ||
      !("is_accessible" in projectRegions[currentRegion])
    ) {
      return "invalid";
    }

    const accessValue = projectRegions[currentRegion]["is_accessible"];

    return accessValue ? "allowed" : "not-allowed";
  };

  const redirectToDashboardPage = useCallback(() => {
    if (!router.isReady) {
      return;
    }
    const { [apiConfig.projectId]: projectId, ...queryWithoutProjectId } =
      queryParams;
    router.push({
      pathname: getRoute("dashboard"),
      query: queryWithoutProjectId,
    });
  }, [queryParams, router]);

  const checkProject = useCallback(() => {
    if (
      !router.isReady ||
      !user ||
      (modalActive.isModalOpen && modalActive.modalName === "projectDialog")
    ) {
      return;
    }

    if (!projects) {
      handleOpenModal("projectDialog");
      return;
    }

    if (!projectValue || !projects[projectValue]) {
      toast(
        `${toastMessage.frontendError.projectNotFoundErrorMessage || "Project not found"}. ${toastMessage.frontendError.dashboardRedirectionMessage || "Redirecting to dashboard page"}`,
        {
          type: "error",
          autoClose: 1500,
          onClose: redirectToDashboardPage,
        },
      );
      return;
    }

    if (!currentRegion) {
      toast(
        `${toastMessage.frontendError.regionNotFoundErrorMessage || "Region not found"}. ${toastMessage.frontendError.dashboardRedirectionMessage || "Redirecting to dashboard page"}`,
        {
          type: "error",
          autoClose: 1500,
          onClose: redirectToDashboardPage,
        },
      );
      return;
    }

    const projectRegions = projects[projectValue].regions;

    if (!projectRegions?.[currentRegion]) {
      router.push(getRouteWithParams("serviceNotAvailable"));
      return;
    }

    const accessStatus: string = isProjectAccessible(
      projectRegions,
      currentRegion,
    );

    switch (accessStatus) {
      case "invalid":
        router.replace(getRouteWithParams("serviceNotAvailable"));
        return;
      case "not-allowed":
        router.replace(getRouteWithParams("getStarted"));
        return;
    }

    setShowFallback(false);
  }, [
    router,
    user,
    projects,
    projectValue,
    redirectToDashboardPage,
    currentRegion,
    handleOpenModal,
    modalActive.isModalOpen,
    modalActive.modalName,
  ]);

  useEffect(() => {
    checkProject();
  }, [checkProject]);

  if (authLoading || user === null) {
    return fallback;
  }

  const handleClose = () => {
    handleCloseModal();
    redirectToDashboardPage();
  };

  return (
    <>
      {showFallback ? fallback : children}
      <ProjectDialog
        open={
          modalActive.isModalOpen && modalActive.modalName === "projectDialog"
        }
        onCloseModal={handleClose}
      />
    </>
  );
};

export default ProjectGuard;
