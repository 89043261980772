import { AxiosRequestConfig, AxiosResponse } from "axios";
import cookie from "js-cookie";
import { KUBERNETES_BASE_URL, infiniteFetchSize } from "src/configs/constants";
import axios from "src/services/axios";
import { generateApiUrl } from "src/configs/helper";
import apiConfig from "src/configs/api";
import { DeleteDataObject } from "src/types/openstackResponse";
import { errorLogInSentry } from "src/store/sentry";
import showToast from "src/utils/notifications";
import { toastMessage } from "src/language/en/message";
import { TABLE_NULL_PLACEHOLDER } from "src/configs/constants";
import { InstanceBatchShutOffType } from "src/types/instances/batch-shutoff";

interface Headers {
  [key: string]: number | string | null;
}

type ResourceIdentifier = string | number;

export const fetchList = async (url: string, params?: {}) => {
  const response = await axios.get(url, { params });

  return response.data ? response.data : {};
};

export const fetchListInfiniteScroll = async (
  url: string,
  params: {
    pageParam: string;
    sorting: { id: string; desc: boolean }[];
    customParam?: { [key: string]: string };
  },
  apiVersion?: string,
  isLimitRequired: boolean = true,
) => {
  const headers: Headers = {};

  if (apiVersion) {
    headers["Openstack-Api-Version"] = apiVersion;
  }
  url = generateApiUrl(url, isLimitRequired);
  if (params && params.customParam) {
    const paramKeys = Object.keys(params.customParam);
    let urlCustomParamString = "";
    paramKeys.forEach((paramKey) => {
      urlCustomParamString +=
        "&" +
          paramKey +
          "=" +
          (params.customParam && params.customParam[paramKey]) || "";
    });

    url += urlCustomParamString;
  }

  if (
    params &&
    params.sorting &&
    Array.isArray(params.sorting) &&
    params.sorting.length
  ) {
    let urlSortParamsString = "&sort_key=";
    params.sorting.forEach((key: { id: string; desc: boolean }) => {
      urlSortParamsString += `${key.id}&sort_dir=${key.desc ? "desc" : "asc"},`;
    });

    url += urlSortParamsString.substring(0, urlSortParamsString.length - 1);
  }

  if (params.pageParam) {
    url += `&marker=${params.pageParam}`;
  }

  return await axios.get(url, { headers });
};

export const fetchData = async (
  url: string,
  params?: {},
  customHeaders?: Headers,
) => {
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...customHeaders,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.get(generateApiUrl(url), config);
    return response && response.data ? response.data : {};
  } catch (error: unknown) {
    throw error;
  }
};

export const postData = async (
  url: string,
  payloadData?: { [key: string]: { [key: string]: string | number | boolean } },
  params?: {},
  customHeaders?: Headers,
) => {
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...customHeaders,
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await axios.post(
      generateApiUrl(url),
      payloadData || {},
      config,
    );
    return response && response.data ? response.data : {};
  } catch (error: unknown) {
    throw error;
  }
};

export const putData = async (
  url: string,
  payloadData?: { [key: string]: { [key: string]: string | number | boolean } },
  params?: {},
  customHeaders?: Headers,
) => {
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...customHeaders,
    },
  };

  try {
    const response = await axios.put(
      generateApiUrl(url),
      payloadData || {},
      config,
    );
    return response && response.data ? response.data : {};
  } catch (error: unknown) {
    throw error;
  }
};

export const deleteData = async (
  url: string,
  params?: {},
  customHeaders?: Headers,
) => {
  const config: AxiosRequestConfig = {
    params,
    headers: {
      ...customHeaders,
    },
  };

  try {
    const response = await axios.delete(generateApiUrl(url), config);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error: unknown) {
    throw error;
  }
};

export const deleteMultipleResources = async (
  baseUrl: string,
  resourceIds: Set<ResourceIdentifier>,
  params?: {},
  customHeaders?: Headers,
): Promise<Set<ResourceIdentifier>> => {
  const deletePromises = Array.from(resourceIds).map(async (id) => {
    try {
      await deleteData(`${baseUrl}/${id}`, params, customHeaders);
      return null;
    } catch (error) {
      errorLogInSentry(
        error,
        {},
        "DELETE",
        "deleteMultipleResources",
        "src/store/store.ts",
      );
      return id;
    }
  });

  if (deletePromises.length === 0) {
    return new Set();
  }

  const results = await Promise.all(deletePromises);
  if (results.length === 0) {
    return new Set();
  }

  const failedCases = new Set(
    results.filter((result) => result !== null) as ResourceIdentifier[],
  );

  return failedCases;
};

export async function sendKubernetesRequest(
  route: string,
  method: string,
  data: any = null,
  headers = {},
) {
  const url = generateApiUrl(`${KUBERNETES_BASE_URL}${route}`, false);

  try {
    const response: AxiosResponse = await axios({ method, url, data, headers });
    return response.data;
  } catch (error: unknown) {
    throw error;
  }
}
export const deleteMultipleEntities = async (
  baseUrl: string,
  resourceValues: { [key: string]: string },
  deletionKey: string,
  params?: {},
  customHeaders?: Headers,
) => {
  try {
    const config: AxiosRequestConfig = {
      data: {
        key: deletionKey,
        values: Object.keys(resourceValues) || [],
      },
      params,
      headers: {
        ...customHeaders,
      },
    };
    const response = await axios.delete(generateApiUrl(baseUrl), config);
    if (response) {
      return response;
    }
    return { error: false, message: "", data: [], status: 400 };
  } catch (error: unknown) {
    throw error;
  }
};

export const extractFailedEntitiesData = async (
  deleteDataArray: DeleteDataObject[],
  multipleDeleteData: { [key: string]: string },
) => {
  if (
    !Array.isArray(deleteDataArray) ||
    deleteDataArray.length === 0 ||
    !multipleDeleteData
  ) {
    return {};
  }

  const result = deleteDataArray.reduce(
    (acc, { error, data, message }) => {
      const name =
        multipleDeleteData[data.key] ?? (data.key || TABLE_NULL_PLACEHOLDER);
      if (error) {
        acc[name] = message;
      }
      return acc;
    },
    {} as { [key: string]: string },
  );

  return result;
};

export const getSuccessfullyDeletedEntityCount = async (
  failedDeleteDataObject: { [key: string]: string },
  totalDeleteDataObject: { [key: string]: string },
) => {
  if (
    !Object.keys(failedDeleteDataObject) ||
    !Object.keys(totalDeleteDataObject) ||
    Object.keys(totalDeleteDataObject).length === 0
  ) {
    return 0;
  }

  return (
    Object.keys(totalDeleteDataObject).length -
    Object.keys(failedDeleteDataObject).length
  );
};

export const handleDeleteApiResponse = async (
  response:
    | AxiosResponse
    | { error: boolean; message: string; data: never[]; status: number },
  multipleDeleteData: { [key: string]: string },
  toastId: string,
  moduleName: string,
) => {
  if (!response) {
    return { isFailedEntry: false, failedEntriesObject: {} };
  }
  if (response.status === 207) {
    const processingData = (response.data && response.data.data) || [];
    const failedEntitiesObject = await extractFailedEntitiesData(
      processingData,
      multipleDeleteData,
    );

    const failedDeletingEntitiesCount: number =
      (Object.keys(failedEntitiesObject) &&
        Object.keys(failedEntitiesObject).length) ||
      0;

    const successfullyDeletedEntitiesCount: number =
      await getSuccessfullyDeletedEntityCount(
        failedEntitiesObject,
        multipleDeleteData,
      );

    if (successfullyDeletedEntitiesCount > 0) {
      showToast(
        "success",
        `${toastMessage.delete.success ?? "Successfully deleted"} ${successfullyDeletedEntitiesCount} ${moduleName}${successfullyDeletedEntitiesCount > 1 ? "s" : ""}.`,
      );
    }

    const infoMessage = `${failedDeletingEntitiesCount > 0 ? `${toastMessage.delete.error ?? "Unable to delete"} ${failedDeletingEntitiesCount}` : `${toastMessage.delete.error3 ?? "Unable to delete all the selected"}`} ${moduleName}${failedDeletingEntitiesCount > 1 ? "s" : ""}`;

    showToast("info", `${infoMessage}`, false, toastId);

    return { isFailedEntry: true, failedEntriesObject: failedEntitiesObject };
  } else if (response.status === 200) {
    showToast(
      "success",
      `${toastMessage.delete.successMultiple ?? "Successfully deleted the selected"} ${moduleName}${Object.keys(multipleDeleteData) && Object.keys(multipleDeleteData).length > 1 ? "s" : ""}`,
      false,
      toastId,
    );
    return { isFailedEntry: false, failedEntriesObject: {} };
  } else {
    showToast(
      "error",
      toastMessage.backendError.errorMessage ??
        "Error occurred while processing the request",
      false,
      toastId,
    );
    return { isFailedEntry: false, failedEntriesObject: {} };
  }
};

export const extractInstanceFailedEntities = async (
  dataArray: InstanceBatchShutOffType[],
  processableEntities: { [key: string]: string },
) => {
  if (
    !Array.isArray(dataArray) ||
    dataArray.length === 0 ||
    !processableEntities
  ) {
    return {};
  }
  const result = dataArray.reduce(
    (failedEntities, { error, data, message }) => {
      const name =
      processableEntities[data.id] ?? (data.id || TABLE_NULL_PLACEHOLDER);

      if (error) {
        failedEntities[name] = message;
      }
      return failedEntities;
    },
    {} as { [key: string]: string },
  );

  return result;
};

export const getSuccessfulEntityCount = async (
  failedEntitiesObject: { [key: string]: string },
  processableEntitiesObject: { [key: string]: string },
) => {
  if (
    !Object.keys(failedEntitiesObject) ||
    !Object.keys(processableEntitiesObject) ||
    Object.keys(processableEntitiesObject).length === 0
  ) {
    return 0;
  }

  return (
    Object.keys(processableEntitiesObject).length -
    Object.keys(failedEntitiesObject).length
  );
};

export const handleMultiStatusResponse = async(
  response: 
  | AxiosResponse 
  | { error: boolean; message: string; data: string[] | InstanceBatchShutOffType[]; status: number },
  processableEntities: {[key: string]: string},
  toastId: string,
  moduleName: string,
  instancesData: {
    title: string;
    confirmationMessage: string;
    apiUrl: string;
    data?: { [key: string]: string | boolean | string[] };
    scheduledMessage: string;
    successMessage: string;
    errorMessage: string;
  }
) => {
  const processingData = (response.data) || [];
  const failedEntitiesObject = await extractInstanceFailedEntities(
    processingData,
    processableEntities,
  );

  const failedEntitiesCount: number = Object.keys(failedEntitiesObject)?.length || 0;

  const successfulEntitiesCount: number =
  await getSuccessfulEntityCount(
    failedEntitiesObject,
    processableEntities,
  );

  successfulEntitiesCount > 0 &&
    showToast("info", `${instancesData.successMessage} ${successfulEntitiesCount} ${moduleName}${successfulEntitiesCount > 1 ? "s" : ""}.`);
  

  failedEntitiesCount > 0 &&
    showToast("error", `${instancesData.errorMessage} ${failedEntitiesCount} ${moduleName}${failedEntitiesCount > 1 ? "s" : ""}`, false, toastId) ;
  
  return { isFailedEntry: true, failedEntriesObject: failedEntitiesObject };
}

export const handleInstanceHeaderActionsApiResponse = async(
  response:
    | AxiosResponse
    | { error: boolean; message: string; data: string[] | InstanceBatchShutOffType[]; status: number },
  processableEntities: { [key: string]: string },
  toastId: string,
  moduleName: string,
  instancesData: {
    title: string;
    confirmationMessage: string;
    apiUrl: string;
    data?: { [key: string]: string | boolean | string[] };
    scheduledMessage: string;
    successMessage: string;
    errorMessage: string;
  }
) => {
  let failureObject = { isFailedEntry: false, failedEntriesObject: {} };
  if (!response) {
    return failureObject;
  }
  if (response.status === 207) {
    return handleMultiStatusResponse(response, processableEntities, toastId, moduleName, instancesData);
  } else if (response.status === 200) {
    toastId &&
    showToast("info", `${instancesData.successMessage} the selected ${moduleName}${Object.keys(processableEntities) && Object.keys(processableEntities).length > 1 ? "s" : ""}.`, false, toastId);
    return failureObject;
  } else {
    toastId &&
    showToast(
      "error",
      toastMessage.backendError.errorMessage ??
      "Error occurred while processing the request",
      false,
      toastId,
    );
    return failureObject;
  }
}